import React from "react"
import Layout from "./components/layout"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const Architektur = () => {
  return (
    <Layout>
      <Container className="">
        <Row className="text-white text-left">
          <h1 className="fw-bold">Holzkette</h1>
          <h2>Holzkette (ABH105)</h2>
        </Row>
        <Row>
          <Col className="pt-5 pb-5">
            <p className="text-col-3 text-white">
              Im Projekt „Holzkette“ Projekt werden Forstwirtschaftsbetriebe und
              Holzverarbeitungsunternehmen entlang der Wertschöpfungskette im
              Grenzraum Österreich, Bayern und Baden-Württemberg zur verstärkten
              Zusammenarbeit für Holz der kurzen und emissionsoptimierten Wege
              animiert. Die institutionellen und technischen Rahmenbedingungen
              werden analysiert und die digitale Geodateninfrastruktur (GDI) zur
              Etablierung von emissionsoptimierten Vertriebswegen und
              Zertifizierungssystemen aufgebaut. Die regionale Zusammenarbeit
              der Betriebe und der nachhaltige Vertrieb des Roh- und Wertstoffs
              wird gestärkt. Gleichzeitig können Transportwege eingespart werden
              und klimawirksame Emissionen reduziert werden. Die Daten können
              einfach für Herkunfts- und Umweltlabels verwendet werden.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="bg-secondary pt-5 pb-5">
        <Container>
          <Row>
            <Col className="pt-5 pb-5">
              <p className="text-col-3">
                Mit dem Projekt „Holzkette“ werden Forstwirtschaftsbetriebe und
                Holzverarbeitungsunternehmen entlang der Wertschöpfungskette im
                Grenzraum Österreich, Bayern und Baden-Württemberg zur
                verstärkten Zusammenarbeit für Holz der kurzen und
                emissionsoptimierten Wege animiert. In einem strukturierten
                Prozess werden institutionelle und technische Rahmenbedingungen
                analysiert und die digitale Geodateninfrastruktur (GDI) zur
                Etablierung von emissionsoptimierten Vertriebswegen und
                Zertifizierungssystemen aufgebaut. Durch die Implementierung der
                GDI werden die technischen Grundlagen für transparente und
                starke Herkunfts- oder Umweltlabels geschaffen. Durch die
                Einbindungen der relevanten Stakeholder
                (Waldbesitzerzusammenschlüsse, Forstbetriebe, Transportlogistik
                und holzverarbeitende Industrie) in den Prozess soll die
                regionale Wertschöpfungskette Holz intensiviert werden und eine
                digitale Austauschplattform ermöglicht werden. Transportwege
                können eingespart und damit verbundene klimawirksame Emissionen
                reduziert werden. Zusätzlich werden durch die
                grenzüberschreitende institutionelle Zusammenarbeit die Betriebe
                und Unternehmen im Bereich der Digitalisierung und der
                Öffentlichkeitsarbeit unterstützt. Ziel des Projektes ist es die
                Grundlagen zu schaffen, um Unternehmen und Betriebe entlang der
                Wertschöpfungskette verstärkt in die Nutzung digitaler
                Instrumente und Geodaten einzubinden, regionale Zusammenarbeit
                und Austausch losgelöst von Ländergrenzen zu etablieren und
                damit Transporte zu reduzieren. Mit der gezielten Unterstützung
                der Betriebe in der Digitalisierung und der digitalen Erfassung
                der Stoff- und Warenströme wird die Effizienz in der
                Bestandsführung, der emissionsarmen Beschaffung bzw. des Handels
                in der gesamten Wertschöpfungskette Holz optimiert und die
                Wettbewerbsfähigkeit gesteigert. Ein weiteres Ziel ist die
                Stärkung und erleichterte Implementierung eines Umweltlabels
                (wie z.B. „Holz von Hier“) in Förderrichtlinien der öffentlichen
                Fördergeber in den jeweiligen Ländern, Landkreisen oder
                Gemeinden. Durch die standardmäßige Implementierung von
                etablierten Herkunfts- oder Umweltlabels in öffentlichen
                Ausschreibungen soll ein nachhaltiger Effekt und verbindlicher
                Anreiz für mehr Regionalität auf Seiten der Wirtschaftsakteure
                erzielt werden. Dazu werden die Ergebnisse aus dem Interreg –
                Alpine Space Projekt „CaSCo“ genutzt. Die dort gewonnen
                Erkenntnisse zur Förderung umweltfreundlicher Holzprodukte
                können 1:1 Anwendung finden. In Summe wird die Effizienz der
                Zusammenarbeit verbessert, die regionalen Stoffflüsse und
                Verfügbarkeiten erfasst, der nachhaltige Vertrieb des Roh- und
                Wertstoffs Holz gestützt und klimaschutz- und
                gesundheitsrelevante Ziele unterstützt.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="bg-secondary pt-5 pb-5">
        <Container className="bg-secondary text-primary bg-opacity-50">
          <Row>
            <Col>
              <h3>Projektitel</h3>
            </Col>
            <Col>
              <p>
                Holzkette: Durch grenzüberschreitende institutionelle
                Zusammenarbeit die lokale Verarbeitungskette stärken, den
                CO2-Ausstoß reduzieren und durch Digitalisierung die Effizienz
                steigern.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="bg-primary text-white bg-opacity-50">
          <Row>
            <Col>
              <h3>Website Projektzeitraum</h3>
            </Col>
            <Col>
              <p>01.06.2020 - 31.05.2023</p>
            </Col>
          </Row>
        </Container>

        <Container className="bg-secondary text-primary  bg-opacity-50">
          <Row>
            <Col>
              <h3>Kofinanzierungssatz</h3>
            </Col>
            <Col>
              <p>EU: 60,00%</p>
            </Col>
          </Row>
        </Container>

        <Container className="bg-primary text-white bg-opacity-50">
          <Row>
            <Col>
              <h3>Beteiligte Länder</h3>
            </Col>
            <Col>
              <p>Deutschland, Österreich</p>
            </Col>
          </Row>
        </Container>

        <Container className="bg-secondary text-primary bg-opacity-50">
          <Row>
            <Col>
              <h3>Leadpartner</h3>
            </Col>
            <Col>
              <p>
                Waldverband Vorarlberg Montfortstraße 9 6900 Bregenz ÖSTERREICH
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="bg-primary text-white bg-opacity-50">
          <Row>
            <Col>
              <h3>Projektpartner</h3>
            </Col>
            <Col>
              <p>– Wald-Säge Fuchstal eG (D) – Holzforum Allgäu (D)</p>
            </Col>
          </Row>
        </Container>

        <Container className="bg-secondary text-primary bg-opacity-50">
          <Row>
            <Col>
              <h4>Kosten</h4>
            </Col>
            <Col>
              <p>600.000,14 €</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Förderung</h4>
            </Col>
            <Col>
              <p>360.000,08 €</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="text-white">Gesamt</h3>
            </Col>
            <Col>
              <p className="text-white">600.000,14 €</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default Architektur
